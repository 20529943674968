<div class="crm-confirm">
  <mat-dialog-content class="crm-confirm__content">
    <h2 class="crm-confirm__title">{{ confirm.title }}</h2>
  </mat-dialog-content>
  <mat-dialog-actions class="crm-confirm__actions">
    <alitrack-button
      *ngIf="confirm.cancel"
      id="crm-confirm__cancel"
      text="Cancelar"
      size="lg"
      color="outline"
      (click)="cancel()"
    >
    </alitrack-button>
    <alitrack-button
      id="crm-confirm__submit"
      [text]="confirm.submit"
      size="lg"
      (click)="submit()"
    >
    </alitrack-button>
  </mat-dialog-actions>
  <alitrack-icon-button
    id="crm-confirm__close"
    class="crm-confirm__close"
    name="close"
    color="white"
    (click)="close()"
  >
  </alitrack-icon-button>
</div>
