import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

import { ErrorResponse, EErrorType } from '@alitrack/crm/commons';
import { environment } from '@shell/environments/environment';
import { UserResponse } from '../models';
import { ApiService } from '../services';

@Injectable()
export class MocksInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return of(null)
      .pipe(
        mergeMap(() => {
          if (!environment.mock) {
            return next.handle(request);
          }

          const body =
            typeof request.body === 'string'
              ? JSON.parse(request.body)
              : request.body;
          const params = request.params;
          const isServerOk = Math.random() > 0.1;

          if (
            request.url.match(this.apiService.generateToken) &&
            request.method === 'POST'
          ) {
            const users: UserResponse[] = require('./json/users.json');
            const account = body.userAccount;
            const password = body.password;
            let userFound = users.find(
              (user: UserResponse) => user.displayName === account,
            );
            if (userFound) {
              // if (userFound.password === password) {
              userFound = JSON.parse(JSON.stringify(userFound));
              
              return this.response(userFound);
              // } else {
                // }
              } else {
                return this.responseError('El usuario o la contraseña que has introducido no es correcta. Intenta de nuevo por favor.');
            }
          }

          // end!
          return next.handle(request);
        }),
      )
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());
  }

  response(data: any): Observable<HttpResponse<any>> {
    data = JSON.parse(JSON.stringify(data));
    console.log('response', data);
    return of(new HttpResponse({ status: 200, body: data }));
  }

  responseError(message: string): Observable<HttpResponse<ErrorResponse>> {
    const error: ErrorResponse = {
      code: 'CMR-001',
      description: message,
      errorType: EErrorType.Functional,
    };
    console.error(error);
    return throwError(error);
  }

  serverDown(): Observable<HttpResponse<ErrorResponse>> {
    const error: ErrorResponse = {
      code: 'CMR-999',
      description: 'Servicio no se encuentra disponible.',
      errorType: EErrorType.Technical,
    };
    console.error(error);
    return throwError(error);
  }
}
