export interface AdministrativeOperatorResponse {
  id: string;
  centerNumber: string;
  centerDescription: string;
  name: string;
}

export class AdministrativeOperator {
  administrativeOperatorId: string;
  centerNumber: string;
  centerDescription: string;
  name: string;

  constructor(administrativeOperator: AdministrativeOperatorResponse) {
    this.administrativeOperatorId = administrativeOperator.id || '';
    this.name = administrativeOperator.name || '';
    this.centerNumber = administrativeOperator.centerNumber || '';
    this.centerDescription = administrativeOperator.centerDescription || '';
  }

  // update(currentUser: User, newUser: UserResponse): User {
  //   currentUser.id = newUser.id ?? currentUser.id;
  //   currentUser.name = newUser.displayName ?? currentUser.name;
  //   currentUser.userPrincipalName = newUser.userPrincipalName ?? currentUser.userPrincipalName;
  //   currentUser.givenName = newUser.givenName ?? currentUser.givenName;
  //   currentUser.mail = newUser.mail ?? currentUser.mail;
  //   currentUser.mobilePhone = newUser.mobilePhone ?? currentUser.mobilePhone;
  //   currentUser.companyName = newUser.companyName ?? currentUser.companyName;
  //   currentUser.city = newUser.city ?? currentUser.city;
  //   currentUser.country = newUser.country ?? currentUser.country;
  //   currentUser.state = newUser.state ?? currentUser.state;
  //   currentUser.jobTitle = newUser.jobTitle ?? currentUser.jobTitle;
  //   currentUser.accountEnabled = newUser.accountEnabled ?? currentUser.accountEnabled;
  //   currentUser.officeLocation = newUser.officeLocation ?? currentUser.officeLocation;
  //   currentUser.region = newUser.region ?? currentUser.region;

  //   return currentUser;
  // }
}
