export enum EErrorType {
  Functional = 'Functional',
  Technical = 'Technical'
}

export enum EErrorTitle {
  Functional = 'Error',
  Technical = 'Error en el sistema'
}

export enum EErrorCode {
  IDFF1 = 'IDFF1',
  IDFF2 = 'IDFF2',
  IDFF3 = 'IDFF3',
  IDFF4 = 'IDFF4'
}
