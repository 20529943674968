import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ProgressbarService, UTILS } from '@alitrack/commons';
import { LocalStorage, SessionStorage } from '@alitrack/commons/storage';
import { MicroAppConfigService } from '@alitrack/crm/commons';
import { environment } from '@shell/environments/environment';
import { Admin, AdminResponse, Token, TokenResponse, User, UserResponse } from '../models';
import { ApiService } from './api.service';
import { DOCUMENT } from '@angular/common';
import { Liquidator } from '../models/liquidator.model';
import { tap } from 'rxjs/operators';
import { EventBus } from '@alitrack/commons/event-bus';
import { AdministrativeOperator, AdministrativeOperatorResponse } from '../models/administrative-operator.model';
import { internalTravelUsers } from '../global/internal-travel-users';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  profile!: ProfileType;
  private userIdentity = new BehaviorSubject<User>(null);
  private liquidatorIdentity = new BehaviorSubject<Liquidator>(null);
  private administrativeOperatorIdentity = new BehaviorSubject<AdministrativeOperator>(null);
  private adminIdentity = new BehaviorSubject<Admin>(null);

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private http: HttpClient,
    private router: Router,
    private progressbarService: ProgressbarService,
    private storage: LocalStorage,
    private session: SessionStorage,
    private config: MicroAppConfigService,
    private apiService: ApiService,
  ) {
    this.loggedIn.next(this.isAuthenticated());
    this.userIdentity.next(this.getUser());
    this.liquidatorIdentity.next(this.getLiquidator());
    this.administrativeOperatorIdentity.next(this.getAdministrativeOperator());
    this.adminIdentity.next(this.getAdmin());
  }
  async getProfile() {
    await this.http
      .get(environment.azureAD.GRAPH_ENDPOINT)
      .toPromise()
      .then(response => {
        this.profile = response;
      });

    return this.profile;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get user() {
    return this.userIdentity.asObservable();
  }
  setUser(userResponse) {
    const user = new User(userResponse);
    this.setState({ user });
    this.loggedIn.next(true);
    this.userIdentity.next(user);
  }

  get liquidator() {
    return this.liquidatorIdentity.asObservable();
  }

  get administrativeOperator() {
    return this.administrativeOperatorIdentity.asObservable();
  }

  get admin() {
    return this.adminIdentity.asObservable();
  }

  async generateToken(body: any = {}): Promise<Token> {
    let tokenResponse: TokenResponse;
    let response;
    let token;
    if (!environment.mock) {
      this.progressbarService.show();
      await this.fetch(this.apiService.generateToken, body)
        .then(res => (response = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });

      if (response) {
        if (response.ok) {
          tokenResponse = await response.json();
        } else {
          this.progressbarService.hide();
          throw await response.json();
        }
      }
      this.progressbarService.hide();
    } else {
      tokenResponse = await this.apiService
        .post(this.apiService.generateToken, body)
        .toPromise();
    }
    token = new Token(tokenResponse);
    this.setState({ token });
    return token;
  }

  async generateTokenSSO(tokenResponse: TokenResponse): Promise<Token> {
    const token = new Token(tokenResponse);
    this.setState({ ...token });
    return token;
  }

  generateAzureToken() {
    this.http
      .get(
        'https://login.microsoftonline.com/' +
        environment.azureAD.tenant_id +
        '/oauth2/v2.0/token',
      )
      .subscribe((response: TokenResponse) => {
        const token = new Token(response);
        this.setState({ token });
      });
  }

  getToken(): Token {
    if (this.storage.get(this.config.stateAuth)) {
      const session = this.storage.get(this.config.stateAuth);
      return session.token;
    } else {
      return null;
    }
  }

  login(user: User) {
    let token = this.getToken();
    let liquidator = this.getLiquidator();
    let administrativeOperator = this.getAdministrativeOperator();
    if (liquidator) {
      this.setState({ token, user, liquidator });
    } else if (administrativeOperator) {
      this.setState({ token, user, administrativeOperator });
    } else {
      this.setState({ token, user });
    }
    this.loggedIn.next(true);
    this.userIdentity.next(user);
    this.liquidatorIdentity.next(liquidator);
    this.administrativeOperatorIdentity.next(administrativeOperator);
  }

  async getCarrier(): Promise<User> {
    let userResponse: UserResponse;
    this.progressbarService.show();
    if (!environment.mock) {
      await this.http
        .get<UserResponse>(this.apiService.user)
        .toPromise()
        .then(res => (userResponse = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    } else {
      userResponse = await this.apiService
        .get(this.apiService.user)
        .toPromise();
    }
    this.progressbarService.hide();
    const user = new User(userResponse);
    user.role = 'customer';
    user.allowedModules = ['pendiente', 'entregado'];
    return user;
  }

  async getUserSSO(tokenResponse): Promise<User> {
    const token = new Token(tokenResponse);
    this.setState({ token });
    let userResponse;
    let liquidatorResponse;
    let administrativeOperatorResponse;
    this.progressbarService.show();
    if (!environment.mock) {
      await this.http
        .get<UserResponse>(this.apiService.userType)
        .toPromise()
        .then(res => (userResponse = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    } else {
      userResponse = await this.apiService
        .get(this.apiService.userType)
        .toPromise();
    }
    this.progressbarService.hide();
    const user = new User(userResponse);
    if (userResponse.userType === 'liquidator') {
      user.role = userResponse.userType;
      user.allowedModules = ['liquidador'];
      user.userPrincipalName = userResponse.username;
      liquidatorResponse = await this.getLiquidatorInformation(
        user.userPrincipalName,
      );
      const liquidator = new Liquidator(liquidatorResponse);
      this.setState({ liquidator });
      user.name = liquidator.name;
      this.setState({ token, liquidator, user });
    } else if (internalTravelUsers.includes(userResponse.userType)) {
      user.role = userResponse.userType;
      user.allowedModules = ['traslados'];
      user.userPrincipalName = userResponse.username;
      let internalTravelUserResponse = await this.getAdministrativeOperatorInformation(
        user.userPrincipalName,
      );
      const administrativeOperator = new AdministrativeOperator(internalTravelUserResponse);
      this.setState({ administrativeOperator });
      user.name = administrativeOperator.name;
      this.setState({ token, administrativeOperator, user });
    } else if (userResponse.userType === 'administrative-operator') {
      user.role = userResponse.userType;
      user.allowedModules = ['traslados'];
      user.userPrincipalName = userResponse.username;
      administrativeOperatorResponse = await this.getAdministrativeOperatorInformation(
        user.userPrincipalName,
      );
      const administrativeOperator = new AdministrativeOperator(administrativeOperatorResponse);
      this.setState({ administrativeOperator });
      user.name = administrativeOperator.name;
      this.setState({ token, administrativeOperator, user });
    } else if (userResponse.userType === 'admin') {
      user.role = userResponse.userType;
      user.allowedModules = ['users-setting', 'settings'];
      user.userPrincipalName = userResponse.username;
      administrativeOperatorResponse = await this.getAdmiInformation(
        user.userPrincipalName,
      );

      const admin = new Admin(administrativeOperatorResponse);
      this.setState({ admin });
      user.name = admin.name;
      this.setState({ token, admin, user });
    } else {
      this.logout()
      return null;
    }

    return user;
  }

  // async getUserSSO(): Promise<User> {
  //   let userResponse;
  //   let liquidatorResponse;
  //   this.progressbarService.show();
  //   if (!environment.mock) {
  //     await this.http
  //       .get<UserResponse>(this.apiService.userType)
  //       .toPromise()
  //       .then(res => userResponse = res)
  //       .catch(error => {
  //         this.progressbarService.hide();
  //         throw error
  //       });
  //   } else {
  //     userResponse = await this.apiService
  //       .get(this.apiService.user)
  //       .toPromise();
  //   }
  //   this.progressbarService.hide();
  //   const user = new User(userResponse);
  //   if (userResponse.userType === 'liquidator') {
  //     user.allowedModules = ['liquidador'];
  //     user.userPrincipalName = userResponse.username
  //   }
  //   liquidatorResponse = await this.getLiquidatorInformation(user.userPrincipalName);
  //   const liquidator = new Liquidator(liquidatorResponse);
  //   this.setState({...liquidator});
  //   user.name = liquidator.name;

  //   this.setState({ ...liquidator });
  //   return user;
  // }

  async getUserType(destination: string): Promise<string> {
    let response;
    const nombre = destination.replace(/@.*$/, '');
    const params = {
      username: nombre,
    };
    this.progressbarService.show();
    if (!environment.mock) {
      await this.apiService
        .get(this.apiService.userType, params)
        .toPromise()
        .then(res => (response = res))
        .catch(error => {
          this.progressbarService.hide();
          response = 'clliente';
        });
    } else {
      await this.apiService
        .get(this.apiService.userType, params)
        .toPromise()
        .then(res => (response = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    }
    this.progressbarService.hide();
    return response.userType;
  }

  getUser(): User {
    if (this.storage.get(this.config.stateAuth)) {
      const session = this.storage.get(this.config.stateAuth);
      return session.user;
    } else {
      return null;
    }
  }

  getLiquidator(): Liquidator {
    if (this.storage.get(this.config.stateAuth)) {
      const session = this.storage.get(this.config.stateAuth);
      return session.liquidator;
    } else {
      return null;
    }
  }

  getAdministrativeOperator(): AdministrativeOperator {
    if (this.storage.get(this.config.stateAuth)) {
      const session = this.storage.get(this.config.stateAuth);
      return session.administrativeOperator;
    } else {
      return null;
    }
  }

  getAdmin(): Admin {
    if (this.storage.get(this.config.stateAuth)) {
      const session = this.storage.get(this.config.stateAuth);
      return session.admin;
    } else {
      return null;
    }
  }

  async getLiquidatorInformation(destination: string): Promise<Liquidator> {
    const params = {
      username: destination,
    };
    let response: Liquidator;
    this.progressbarService.show();
    await this.http
      .get<Liquidator>(`${this.apiService.liquidatorInformation}`, {
        params: params,
      })
      .toPromise()
      .then(res => (response = res))
      .catch(error => {
        this.progressbarService.hide();
        this.router.navigate(['']);
        throw error;
      });
    // if (!environment.mock) {
    //     // travelsResponse = await this.http.get<Travel[]>(this.apiService.travels, params).toPromise();
    //     travelsResponse = await this.apiService.get(this.apiService.travels, params).toPromise();
    // } else {
    //     travelsResponse = await this.apiService.get(this.apiService.travels).toPromise();
    // }
    this.progressbarService.hide();
    return response;
  }

  async getAdministrativeOperatorInformation(destination: string): Promise<AdministrativeOperatorResponse> {
    const params = {
      username: destination,
    };
    let response: AdministrativeOperatorResponse;
    this.progressbarService.show();
    await this.http
      .get<AdministrativeOperatorResponse>(`${this.apiService.administrativeOperatorInformation}`, {
        params: params,
      })
      .toPromise()
      .then(res => (response = res))
      .catch(error => {
        this.progressbarService.hide();
        this.router.navigate(['']);
        throw error;
      });
    // if (!environment.mock) {
    //     // travelsResponse = await this.http.get<Travel[]>(this.apiService.travels, params).toPromise();
    //     travelsResponse = await this.apiService.get(this.apiService.travels, params).toPromise();
    // } else {
    //     travelsResponse = await this.apiService.get(this.apiService.travels).toPromise();
    // }
    this.progressbarService.hide();
    return response;
  }

  async getAdmiInformation(username: string): Promise<AdminResponse> {
    const params = {
      username: username,
    };
    let response: AdminResponse;
    this.progressbarService.show();
    await this.http
      .get<any>(`${this.apiService.adminInformation}`, {
        params: params,
      })
      .toPromise()
      .then(res => (response = res))
      .catch(error => {
        this.progressbarService.hide();
        this.router.navigate(['']);
        throw error;
      });
    // if (!environment.mock) {
    //     // travelsResponse = await this.http.get<Travel[]>(this.apiService.travels, params).toPromise();
    //     travelsResponse = await this.apiService.get(this.apiService.travels, params).toPromise();
    // } else {
    //     travelsResponse = await this.apiService.get(this.apiService.travels).toPromise();
    // }
    this.progressbarService.hide();
    return response;
  }

  isAuthenticated(): boolean {
    if (this.getUser()) {
      // const token = this.getToken();
      // if (token) {
      return true;
      // } else {
      // this.logout();
      // return false;
      // }
    } else {
      return false;
    }
  }

  logout() {
    this.loggedIn.next(false);
    this.userIdentity.next(null);
    this.liquidatorIdentity.next(null);
    this.administrativeOperatorIdentity.next(null);
    // if(this.msalService.instance.getAllAccounts().length > 0) {
    //   this.msalService.logoutRedirect();
    // }
    this.storage.clear();
    this.session.clear();
    this.router.navigate(['']);
    if (UTILS.isIE()) {
      window.location.reload();
    }
  }

  fetch(url: string, body?: any, method: 'GET' | 'POST' = 'POST') {
    return fetch(url, {
      method,
      // mode: 'no-cors',
      body: body ? JSON.stringify(body) : null,
      headers: {
        'Content-Type': 'application/json',
        'Request-ID': '550e8400-e29b-41d4-a716-446655440000',
        'request-date': this.toISOLocal(new Date()),
        'app-code': 'Meteoro',
        'Ocp-Apim-Subscription-Key': '34128949326e49d5bcc930f171707a12',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    });
  }

  toISOLocal(d) {
    const z = n => ('0' + n).slice(-2);
    let off = d.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);
    return (
      new Date(d.getTime() - d.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -1) +
      sign +
      z((off / 60) | 0) +
      ':' +
      z(off % 60)
    );
  }

  refreshToken(user: User) {
    return this.http.post<TokenResponse>(this.apiService.refreshToken, '')
      .pipe(tap((newToken) => {
        const token = new Token(newToken);
        this.setState({ token, user });
      }));
  }

  private setState(data: any): void {
    this.storage.set(this.config.stateAuth, data);
  }
}
