import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';

import { Menus } from '../global';
import { TokenResponse } from '../models';
import { AuthService } from '../services';

@Injectable()
export class SSOGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      let response: TokenResponse;
      this.activatedRoute.fragment
        .pipe(
          map(fragment => new URLSearchParams(fragment)),
          map(params => ({
            access_token: params.get('access_token'),
            id_token: params.get('id_token'),
          })),
        )
        .subscribe((res: TokenResponse) => (response = res));
      console.log(response);
      if (
        response &&
        response.id_token
      ) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    } else {
      this.router.navigate(['']);
      return false;
    }
    // if (!this.authService.isAuthenticated()) {
    //   return true;
    // } else {
    //   const user = this.authService.getUser();
    //   const allowedMenus = Menus.filter(menu =>
    //     user.allowedModules.includes(menu.id),
    //   );
    //   this.router.navigate([`/${allowedMenus[0].url}`]);
    //   return false;
    // }
  }
}
