import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { UTILS } from '@alitrack/commons';
import { EErrorType, EErrorTitle, ErrorResponse } from '@alitrack/crm/commons';
import { MessageBus } from '@alitrack/commons/message-bus';
import { environment } from '@shell/environments/environment';
import { AuthService } from '../services';
import { MsalService } from '@azure/msal-angular';
import { Token, TokenResponse, User } from '../models';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private messageBus: MessageBus,
    private authService: AuthService,
    private msalService: MsalService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: Token = this.authService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }
    request = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Ocp-Apim-Subscription-Key', '34128949326e49d5bcc930f171707a12')
        .set('app-code', 'Meteoro')
        .set('Request-ID', '550e8400-e29b-41d4-a716-446655440000')
        .set('request-date', this.authService.toISOLocal(new Date()))
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0')
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && this.msalService.instance.getAllAccounts().length === 0) {
          // this.authService.logout();

          return this.handleRefreshToken(request, next);

        } else {
          return throwError(error);
        }
      })
    );
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  private addToken(request: HttpRequest<any>, token: Token) {
    return request.clone({
      headers: request.headers
        .set('Authorization', `${token.tokenType} ${token.idToken}`)
        .set('Access', `${token.tokenType} ${token.accessToken}`)
        .set('refresh-token', token.refreshToken)
    });
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const user: User = this.authService.getUser();
      return this.authService.refreshToken(user).pipe(
        switchMap((tokenResponse) => {
          this.isRefreshing = false;
          const token = new Token(tokenResponse)
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        }));
    }
  }
}
