export interface LiquidatorResponse {
  liquidatorId: string;
  centerNumber: string;
  centerDescription: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export class Liquidator {
  liquidatorId: string;
  centerNumber: string;
  centerDescription: string;
  name: string;
  email: string;
  phoneNumber: string;

  constructor(liquidator: LiquidatorResponse) {
    this.liquidatorId = liquidator.liquidatorId || '';
    this.name = liquidator.name || '';
    this.email = liquidator.email || '';
    this.phoneNumber = liquidator.phoneNumber || '';
    this.centerNumber = liquidator.centerNumber || '';
    this.centerDescription = liquidator.centerDescription || '';
  }
}