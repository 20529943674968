export const environment = {
  production: true,
  mock: false,
  standalone: false,
  version: 'v1',
  api: {
    v1: {
      auth: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-auth-v1',
      travels: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-travels-v1/v1',
      deliveries: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-deliveries-v1/v1',
      liquidations: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-liquidations-v1/v1',
      blobstorage: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-blobstorage-v1/v1',
      admin: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-admin-v1/v1',
    }
  },
  cdn: 'https://www.alitrack.alicorp.com.pe/assets',
  apiBase: 'https://gw.alitrack.alicorp.com.pe/',
  azureAD: {

    //* PARA ENTRAR COMO ALICORP BEGIN */
    client_id: '8d0b1f13-c573-4667-900b-48e5981537e7',
    tenant_id: '3b16616d-f174-497a-a1ae-900d85106996',
    //* PARA ENTRAR COMO ALICORP END */

    //* PARA ENTRAR COMO TRANSPORTISTA BEGIN */
    // client_id: 'a6ccfaed-1667-43f8-b172-899683f89d6a',
    // tenant_id: '3b16616d-f174-497a-a1ae-900d85106996',
    //* PARA ENTRAR COMO TRANSPORTISTA END */

    GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
    redirectUri: 'https://www.alitrack.alicorp.com.pe/auth-response/',
  },
};
