import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { UTILS } from '@alitrack/commons';
import { SessionStorage } from '@alitrack/commons/storage';
import { User, AuthService, Menus, TokenResponse } from '@shell/app/core';
import { Liquidator, SnackbarService } from '@alitrack/crm/commons';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AdministrativeOperator } from '@shell/app/core/models/administrative-operator.model';

@Injectable()
export class ShellPresenter implements OnDestroy {
  isLoggedIn$: Observable<boolean>;
  user$: Observable<User>;
  liquidator$: Observable<Liquidator>;
  administrativeOperator$: Observable<AdministrativeOperator>;
  isMobile = UTILS.isMobile();
  menus = Menus;
  currentModule = '';
  page = '';
  currentView = '';
  isLogin = false;
  user: User;
  private userSubscription$ = new Subscription();
  private routerSubscription$ = new Subscription();

  constructor(
    private router: Router,
    private session: SessionStorage,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.user$ = this.authService.user;
    this.liquidator$ = this.authService.liquidator;
    this.administrativeOperator$ = this.authService.administrativeOperator;
    this.routerSubscribe();
    this.verifyACL();
    this.user = this.authService.getUser();
    // this.getUser();
  }

  // async getUser() {
  //   this.user = await this.authService.getCarrier();

  // }


  routerSubscribe() {
    this.routerSubscription$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.urlAfterRedirects.substring(1).split('/');
        this.currentModule = urls[0];
        this.page = urls[1];
        this.currentView = urls.length > 1 ? urls[1] : '';
      }
    });
  }

  verifyACL() {
    this.userSubscription$ = this.user$.subscribe(user => {
      if (user) {
        this.menus.forEach(
          menu => (menu.show = user.allowedModules.includes(menu.id)),
        );
        if (user.allowedModules.includes('pendiente')) {
          this.session.set('customerInit', true);
        }
        if (this.isLogin) {
          this.router.navigate([
            `/${this.menus.filter(menu => menu.show)[0].url}`,
          ]);
        }
      }
    });
  }

  login(user: User) {
    this.isLogin = true;
    this.authService.login(user);
  }

  private loginRequest: RedirectRequest = {
    scopes: ['openid'],
  };

  initLogin() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect(this.loginRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  logout() {
    this.authService.logout();
  }

  signinAsAlicorp(token: TokenResponse) {
    console.log('one');
    if (token && token.id_token) {
      this.authService
        .getUserSSO(token)
        .then(user => {
          if (user) {
            this.user = user;
            this.login(user);
          }
        })
        .catch(error => {
          console.log('error on signinAsAlicorp: ', error);
          this.snackbarService.show(error.message, 'error');
          this.router.navigate(['']);
          throw error;
        });
    }
  }

  ngOnDestroy() {
    this.routerSubscription$.unsubscribe();
    this.userSubscription$.unsubscribe();
  }
}
