import { Component, OnInit } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AuthService, TokenResponse } from '@shell/app/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ShellPresenter } from './shell/views/shell/shell.presenter';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ShellPresenter]
})
export class AppComponent implements OnInit{
  user: any;
  isIframe = false;
  tokenAzure: TokenResponse;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private presenter: ShellPresenter
    ) {
    }

  ngOnInit() {
    // this.msalService.handleRedirectObservable()
    // .pipe(
    //   takeUntil(this._destroying$)
    // )
    // .subscribe({
    //   next: (result: AuthenticationResult) => {
    //     console.log('shell: ', result);
    //     if (result && result.idToken) {
    //       this.msalService.instance.setActiveAccount(result.account);
    //       this.tokenAzure = {
    //               id_token: result.idToken,
    //               access_token: result.accessToken,
    //               refresh_token: '',
    //               scope: '',
    //               expires_in: '',
    //               ext_expires_in: '',
    //               token_type: result.tokenType
    //             }
    //             if(this.authService.getUser()) {
    //               console.log('there is user')
    //               this.authService.generateTokenSSO(this.tokenAzure)
    //             } else {
    //               // const user = {
    //               //   id: '',
    //               //   name: result.account.name,
    //               //   userPrincipalName: result.account.username,
    //               //   givenName: result.account.name,
    //               //   mail: result.account.username,
    //               //   // mobilePhone: '',
    //               //   // companyName: '',
    //               //   // city: '',
    //               //   // country: '',
    //               //   // state: '',
    //               //   // jobTitle: '',
    //               //   // accountEnabled: '',
    //               //   // officeLocation: '',
    //               //   // region: '',
    //               //   // allowedModules: ['']
    //               // }
    //               // this.authService.setUser(user)
    //               // this.eventBus.$emit('signinAsAlicorp', this.tokenAzure)
    //               console.log('sign in as alicorp')
    //               this.presenter.signinAsAlicorp(this.tokenAzure)
    //             }
    //             // this.presenter.signAsAlicorp(this.tokenAzure)
    //     }
    //   },
    //   error: (error) => console.log(error)
    // });

    // this.msalBroadcastService.msalSubject$
    // .pipe(
    //   filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
    //   )
    //   .subscribe((result: EventMessage) => {
    //     console.log('msalSubject: ', result);
    //     const payload = result.payload as AuthenticationResult;
    //     this.msalService.instance.setActiveAccount(payload.account);
    //     this.tokenAzure = {
    //       id_token: payload.idToken,
    //       access_token: payload.accessToken,
    //       refresh_token: '',
    //       scope: '',
    //       expires_in: '',
    //       ext_expires_in: '',
    //       token_type: payload.tokenType
    //     }
    //     // this.authService.generateTokenSSO(this.tokenAzure)
    //     if(this.authService.getUser()) {
    //       console.log('there is user')
    //       this.authService.generateTokenSSO(this.tokenAzure)
    //     } else {
    //       const user = {
    //         id: '',
    //         name: payload.account.name,
    //         userPrincipalName: payload.account.username,
    //         givenName: payload.account.name,
    //         mail: payload.account.username,
    //         // mobilePhone: '',
    //         // companyName: '',
    //         // city: '',
    //         // country: '',
    //         // state: '',
    //         // jobTitle: '',
    //         // accountEnabled: '',
    //         // officeLocation: '',
    //         // region: '',
    //         // allowedModules: ['']
    //       }
    //       this.authService.setUser(user)
    //       // this.eventBus.$emit('signinAsAlicorp', this.tokenAzure)
    //       console.log('sign in as alicorp')
    //       this.presenter.signinAsAlicorp(this.tokenAzure)
    //     }
    //     // this.eventBus.$emit('loginSuccess')
    //     // this.presenter.signAsAlicorp(this.tokenAzure)
    //   });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
