import { Component, Input } from '@angular/core';

@Component({
  selector: 'alitrack-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input('styl') styl:string="";
  classes: string[];

  ngOnInit() {
    this.classes = [
      'card', this.styl
    ];
  }
}
