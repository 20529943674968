export * from './api.service';
export * from './travels.service';

import { CRMApiService } from './api.service';
import { TravelsService } from './travels.service';

export const Services = [
  CRMApiService,
  TravelsService
];
