export interface Destination {
  destinationId: string;
  suppliesDestinationNumber: string;
  suppliesDestinationName: string;
  paymentAgainstDelivery: string;
  documentaryFlow: string;
  revised: boolean;
  destinationStatus: string;
  status: string;
  positions: Position[];
  observations?: Observation[]
  revisedObservation: boolean;
  isObserved:boolean;
  isSaleOrgLiquidate: boolean;
}
export interface DestinationResponse {
  destinationId: number;
  suppliesDestinationNumber: string;
  suppliesDestinationName: string;
  paymentAgainstDelivery: string;
  documentaryFlow: string;
  revised: boolean;
  destinationStatus: string;
  status: string;
}

export interface Position {
  positionId:string;
  sunatNumber: string;
  materialcod: string;
  materialcodDescription: string;
  materialMeasureUnit: string;
  rejectionQuantity: number;
  reasonDescription: string;
  materialQuantity:number;
  batchs?:Batch[];
  isValid?:boolean;
  editEnabled?:boolean;
  saveEnabled?:boolean;
  alreadySavedData?:boolean;
  batchsEnabled?:boolean;
}

export interface TransferPositionResponse {
  materialCod: string;
  materialDescription: string;
  material_measure_unit: string;
  materialQuantity: number;
  position_weight: number;
}

export class TransferPosition {
  materialCod: string;
  materialDescription: string;
  materialMeasureUnit: string;
  materialQuantity: number;
  positionWeight: number;

  constructor(transferPosition: TransferPositionResponse) {
    this.materialCod = transferPosition.materialCod ? transferPosition.materialCod : '';
    this.materialDescription = transferPosition.materialDescription ? transferPosition.materialDescription : '';
    this.materialMeasureUnit = transferPosition.material_measure_unit ? transferPosition.material_measure_unit : '';
    this.materialQuantity = transferPosition.materialQuantity ? transferPosition.materialQuantity : null;
    this.positionWeight = transferPosition.position_weight ? transferPosition.position_weight : null;
  }
}

export interface Observation {
  destinationId: number;
  observationId: number;
  observationDescription: string;
}

export interface DestinationHeaders {
  buttoType: string;
  buttonActivated: boolean;
  message: string;
  pendingForConfirmation: number;
  pendingForRevise: number;
  revisedQueantity: number;
  totalQueantity: number;
}

export interface Observation{
  observationId:number;
  observationCode:string;
  observationDescription:string;
  checked:boolean;
  positionOrder:number;
}

export interface Batch{
  batchId:number;
  positionId:number,
  batch:string,
  subPositionNumber:string,
  quantity:number,
  rejectedQuantity:number,
  isDisabled?:boolean,
  isValid?:boolean
}
