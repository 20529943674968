export const Confirms = {
  saveUser: {
    title: '¿Estás seguro de guardar los cambios realizados?',
    cancel: 'Cancelar',
    submit: 'Confirmar',
  },
  deleteUser: {
    title: '¿Está seguro de eliminar este usuario?',
    cancel: 'Cancelar',
    submit: 'Confirmar',
  },
  sendTokenQR: {
    title: '¿Desea enviar el Token y QR al cliente?',
    cancel: 'Cancelar',
    submit: 'Confirmar',
  },
  
  tokenQRSent: {
    title: 'Se envió el Token y QR al correo del cliente',
    submit: 'Entendido',
  },
};
