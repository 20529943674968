export interface TokenResponse {
  access_token: string;
  expires_in: string;
  ext_expires_in: string;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export class Token {
  accessToken: string;
  expiresInTime: number;
  idToken: string;
  refreshToken: string;
  scope: string;
  tokenType: string;

  constructor(token?: TokenResponse) {
    this.accessToken = token && token.access_token ? token.access_token : '';
    this.idToken = token && token.id_token ? token.id_token : '';
    this.refreshToken = token && token.refresh_token ? token.refresh_token : '';
    this.scope = token && token.scope ? token.scope : '';
    this.tokenType = token && token.token_type ? token.token_type : 'Bearer';
    this.expiresInTime = Number(token.expires_in) * 1000 + new Date().getTime();
  }
}
