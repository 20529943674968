export enum EUserRole {
  ADMINISTRATIVE_OPERATOR   = 'administrative-operator',
  CUSTOMER                  = 'customer',
  ADMIN                     = 'admin',
  LIQUIDATOR                = 'liquidator',
  MANAGER                   = 'manager',
  TRANSFER_BOSS             = 'transfer-boss',
  ANALYST                   = 'analyst',
}

export enum EUserRoleLabel {
  "Operador Administrativo"   = "administrative-operator",
  'Gerente'                  = 'manager',
  'Jefe de Transporte - Almacén'         = 'transfer-boss',
  'Analista de TRansporte - Alamacén'    = 'analyst',
}
