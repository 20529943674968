export interface AdminResponse {
    id: string;
    centerNumber: string;
    email: string;
    name: string;
  }
  
  export class Admin {
    adminId: string;
    email: string;
    name: string;
  
    constructor(adminResponse: AdminResponse) {
      this.adminId = adminResponse.id || '';
      this.name = adminResponse.name || '';
      this.email = adminResponse.email || '';
    }
  }
  