export interface AdminPriceGroup {
  code: string;
  coordinatorMaxAmount: string;
  description: string;
  maxAmount: string;
}

export interface AdminRegion {
  coordinatorType: string;
  name: number;
  transportZones: TransporZone[];
}

export interface TransporZone {
  code: number;
  description: string;
}

export interface MontaCarga {
  centerName?:string;
  centerNumber: number;
  plateNumber: string;
}

export class RegionIdentifier {
  value: string;
  label: string;

  constructor(adminRegion: AdminRegion) {
    this.value = adminRegion.name.toString() || '';
    this.label = adminRegion.name.toString() || '';
  }
}

export interface AdminCenter {
    centerCoordinates:string,
    centerName: string,
    centerNumber: number,
    isInternalTravel: boolean
  }
