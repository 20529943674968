export enum EButtonType {
  'button' = 'button',
  'submit' = 'submit'
}

export enum EButtonStyle {
  'flat' = 'flat',
  'basic' = 'basic'
}

export enum EButtonColor {
  'primary' = 'primary',
  'outline' = 'outline'
}

export enum EButtonSize {
  'lg' = 'lg',
  'md' = 'md',
  'sm' = 'sm',
}
