<button
  *ngIf="styl === 'icon'"
  [disabled]="disabled"
  [ngClass]="classes"
  (click)="onClicked($event)"
  mat-icon-button
>
  <alitrack-icon
    [name]="name"
    class="d-flex justify-content-center"
  ></alitrack-icon>
</button>
<button
  *ngIf="styl === 'fab'"
  [disabled]="disabled"
  [ngClass]="classes"
  (click)="onClicked($event)"
  mat-mini-fab
>
  <alitrack-icon
    [name]="name"
    class="d-flex justify-content-center"
  ></alitrack-icon>
</button>
<button
  *ngIf="styl === 'flat'"
  styl="flat"
  [type]="type"
  [disabled]="disabled"
  [ngClass]="classes"
  (click)="onClicked($event)"
  mat-flat-button
>
  <alitrack-icon
    *ngIf="name"
    [name]="name"
    class="d-flex justify-content-center mr-2"
  ></alitrack-icon>
  {{ text }}
</button>
<button
  *ngIf="styl === 'light'"
  [type]="type"
  [disabled]="disabled"
  [ngClass]="classes"
  (click)="onClicked($event)"
  mat-button
>
  {{ text }}
  <alitrack-icon
    *ngIf="name"
    [name]="name"
    class="d-flex justify-content-center ml-2"
  ></alitrack-icon>
</button>
<button
  *ngIf="styl === 'basic'"
  [type]="type"
  [disabled]="disabled"
  [ngClass]="classes"
  (click)="onClicked($event)"
  mat-button
>
  {{ text }}
  <alitrack-icon
    *ngIf="name"
    [name]="name"
    class="d-flex justify-content-center mr-2"
  ></alitrack-icon>
</button>
<button [type]="type" class="link-button" *ngIf="styl === 'link'" [disabled]="disabled" [ngClass]="classes" (click)="onClicked($event)">
  <alitrack-icon
    *ngIf="name && alignIcon === 'left'"
    [name]="name"
    class="d-flex justify-content-center mr-2"
  ></alitrack-icon>
  {{ text }}
  <alitrack-icon
    *ngIf="name && alignIcon === 'right'"
    [name]="name"
    class="d-flex justify-content-center"
  ></alitrack-icon>
</button>
