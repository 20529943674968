export enum EIconButtonType {
  'button' = 'button',
  'submit' = 'submit'
}

export enum EIconButtonStyle {
  'icon' = 'icon',
  'fab' = 'fab',
  'flat' = 'flat',
  'basic' = 'basic',
  'light' = 'light',
  'link' = 'link'
}

export enum EIconButtonColor {
  'primary' = 'primary',
  'light' = 'light',
  'white' = 'white',
  'secondary' = 'secondary'
}

export enum EIconButtonSize {
  'lg' = 'lg',
  'md' = 'md',
  'sm' = 'sm'
}
