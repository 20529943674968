export interface Center {
  centerId: number;
  centerNumber: string;
  centerDescription: string;
  centerAddress: string;
  positionWeightWithUnit: string;
  positionWeight: number;
  purchaseOrder: number;
  qualityCertificate: number;
  departureTime: string;
}

export interface CenterAdmin {
  centerCoordinates: string;
  centerNumber: string;
  centerName: string;
  isInternalTravel: boolean;
}

export class CenterIdentifier {
  value: string;
  label: string;
  abbreviation: string;
  constructor(centerIdentifier: Center) {
    this.value = centerIdentifier.centerNumber
      ? centerIdentifier.centerNumber
      : '';
    this.label = centerIdentifier.centerDescription
      ? centerIdentifier.centerDescription
      : '';
    this.abbreviation = centerIdentifier.centerDescription
      ? centerIdentifier.centerDescription
      : '';
  }
}

export class CenterAdminIdentifier {
  value: string;
  label: string;
  abbreviation: string;
  constructor(centerIdentifier: CenterAdmin) {
    this.value = centerIdentifier.centerNumber || '';
    this.label = centerIdentifier.centerName || '';
    this.abbreviation = centerIdentifier.centerName || '';
  }
}
