import { Menu } from '../interfaces';

export const Menus: Menu[] = [
  { id: 'liquidador', url: 'transportes', title: 'Transportes', show: true },
  { id: 'traslados', url: 'traslados', title: 'Traslado', show: true },
  { id: 'users-setting', url: 'usuarios', title: 'Configuración de Usuarios', show: true },
  { id: 'settings', url: 'configuracion', title: 'Configuración Alitrack', show: true },
  { id: 'pendiente', url: 'pendiente', title: 'Transporte Pendiente', show: true },
  { id: 'entregado', url: 'entregado', title: 'Transporte Entregado', show: true }
];
