import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ProgressbarService } from '@alitrack/commons';
import { environment } from '@shell/environments/environment';
import { LocalStorage } from '@alitrack/commons/storage';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import {
  Delivery,
  Liquidator,
  LiquidatorTab,
  CheckDelivery,
  CheckDeliveryInObservation,
} from '@shell/app/core';
import { CRMApiService } from './api.service';
import {
  Batch,
  Destination,
  DestinationHeaders,
  Observation,
  Position,
  TransferPosition,
  TransferPositionResponse,
} from '../interfaces/destination.interface';
import { MicroAppConfigService } from '../microapp-loader/microapp-config.service';
import { Center, CenterAdmin, CenterAdminIdentifier, CenterIdentifier, TransferTravel, TransferTravelResponse, Travel, TravelPhotos } from '../interfaces';
import { LiquidatorValidateQR } from '../interfaces/liquidatorValidateQR';
import { DeliveryConfirmed } from '../enums/DeliveryConfirmed';
import * as moment from 'moment';
import { Params } from '@angular/router';
import { AdminRegion, RegionIdentifier } from '../interfaces/adminConfiguration.interface';

@Injectable({
  providedIn: 'root',
})
export class TravelsService {
  private $travels = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private progressbarService: ProgressbarService,
    private apiService: CRMApiService,
    private storage: LocalStorage,
    private config: MicroAppConfigService,
  ) {}
  async getDelivery(destination: string, travelId: number): Promise<Delivery> {
    const nombre = destination.replace(/@.*$/, '');
    const params = {
      'destination-number': nombre,
      'travel-id': travelId,
    };
    let travelResponse: Delivery;
    this.progressbarService.show();
    if (!environment.mock) {
      await this.apiService
        .get(this.apiService.delivery, params)
        .toPromise()
        .then(res => (travelResponse = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    } else {
      travelResponse = await this.apiService
        .get(this.apiService.delivery)
        .toPromise();
    }
    this.progressbarService.hide();
    return travelResponse[0];
  }

  async getSKU(
    destination: string,
    travelId: number,
    guideId: number,
  ): Promise<any> {
    const nombre = destination.replace(/@.*$/, '');
    let travelResponse: any;
    this.progressbarService.show();
    if (!environment.mock) {
      await this.apiService
        .get(
          `${this.apiService.delivery}/travels/${travelId}/deliveries/${nombre}/guides/${guideId}/positions`,
        )
        .toPromise()
        .then(res => (travelResponse = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    } else {
      travelResponse = await this.apiService
        .get(this.apiService.delivery)
        .toPromise();
    }
    this.progressbarService.hide();
    return travelResponse;
  }

  async getTravelsPending(destination: string): Promise<Travel[]> {
    const nombre = destination.replace(/@.*$/, '');
    const params = {
      'destination-number': nombre,
    };
    let travelsResponse: Travel[];
    let response;
    this.progressbarService.show();
    if (!environment.mock) {
      await this.apiService
        .get(this.apiService.travels, params)
        .toPromise()
        .then(res => (travelsResponse = res))
        .catch(error => {
          this.progressbarService.hide();
          throw error;
        });
    } else {
      travelsResponse = await this.apiService
        .get(this.apiService.travels)
        .toPromise();
    }
    this.progressbarService.hide();
    this.storage.set('alitrack-travels', travelsResponse);
    return travelsResponse;
  }

  async confirmDelivery(deliveryId): Promise<DeliveryConfirmed> {
    this.progressbarService.show();
    let response: DeliveryConfirmed = null;
    await this.http
      .post(`${this.apiService.delivery}/${deliveryId}`, '', {
        observe: 'response',
      })
      .pipe(
        map(
          (response)=>{
            return DeliveryConfirmed.CONFIRMED
          }
        ),
        catchError(
          (error:HttpErrorResponse)=>{
            let temp;
            if (error.status===400) {
              switch (error.error.code) {
                case "ATK0006":
                  temp=DeliveryConfirmed.ALREADY_CONFIRMED;
                  break;
                case "ATK0008":
                  temp=DeliveryConfirmed.TIMEOUT;
                default:
                  temp=null;
                  break;
              }
            }
            return of(temp)
          }
        )
      )
      .toPromise()
      .then(res => {
        response = res;
      })
      .catch((error:HttpErrorResponse) => {
        this.progressbarService.hide();
      });
    this.progressbarService.hide();
    return response;
  }

  async getLiquidatorTitlesWithCount(
    centerNumber: string,
    request: {
      datesearch: { dateStart: string; dateEnd: string } | null;
      textsearch: string[] | null;
    },
  ): Promise<LiquidatorTab> {
    let params = {
      'center-number': centerNumber,
      type: 'all',
    };

    if (request.datesearch) {
      params['date'] = request.datesearch.dateStart;
      params['end-date'] = request.datesearch.dateEnd
        ? request.datesearch.dateEnd
        : request.datesearch.dateStart;
    } else {
      params['number-transport'] = request.textsearch;
    }

    let travelsResponse: LiquidatorTab;
    this.progressbarService.show();
    travelsResponse = await this.http
      .get<LiquidatorTab>(
        `${this.apiService.liquidations}/number-travels-by-status`,
        {
          params: params,
        },
      )
      .pipe(
        map((tabtitles: any) => {
          return {
            pending: tabtitles.pendingCount,
            revised: tabtitles.revisedCount,
            observed: tabtitles.observedCount,
            liquidated: tabtitles.liquidatedCount,
          };
        }),
      )
      .toPromise();

    this.progressbarService.hide();
    this.storage.set('liquidation-states', travelsResponse);
    return travelsResponse;
  }
  async getLiquidatorTitlesWithCountLiquidated(
    centerNumber: string,
  ): Promise<LiquidatorTab> {
    let params = {
      'center-number': centerNumber,
      type: 'all',
    };

    let travelsResponse: LiquidatorTab;
    this.progressbarService.show();
    travelsResponse = await this.http
      .get<LiquidatorTab>(
        `${this.apiService.liquidations}/number-travels-by-status`,
        {
          params: params,
        },
      )
      .pipe(
        map((tabtitles: any) => {
          return {
            pending: tabtitles.pendingCount,
            revised: tabtitles.revisedCount,
            observed: tabtitles.observedCount,
            liquidated: tabtitles.liquidatedCount,
          };
        }),
      )
      .toPromise();

    this.progressbarService.hide();
    this.storage.set('liquidation-states', travelsResponse);
    return travelsResponse;
  }

  async getLiquidationDataByType(
    centerNumber: string,
    specificType: string,
    request: {
      datesearch: { dateStart: string; dateEnd: string } | null;
      textsearch: string[] | null;
    },
  ): Promise<[]> {
    let params = {
      'center-number': centerNumber,
      type: specificType,
    };
    if (request.datesearch) {
      params['date'] = request.datesearch.dateStart;
      params['end-date'] = request.datesearch.dateEnd
        ? request.datesearch.dateEnd
        : request.datesearch.dateStart;
    } else {
      params['number-transport'] = request.textsearch;
    }

    let travelsResponse: [];
    this.progressbarService.show();
    travelsResponse = await this.http
      .get<[]>(`${this.apiService.liquidations}/travels`, { params: params })
      .toPromise();
    this.progressbarService.hide();
    this.storage.set('liquidation-travels', travelsResponse);
    return travelsResponse;
  }
  async getLiquidationDataByTypeLiquidated(
    centerNumber: string,
    specificType: string,
  ): Promise<[]> {
    let params = {
      'center-number': centerNumber,
      type: specificType,
    };

    let travelsResponse: [];
    this.progressbarService.show();
    travelsResponse = await this.http
      .get<[]>(`${this.apiService.liquidations}/travels`, { params: params })
      .toPromise();
    this.progressbarService.hide();
    this.storage.set('liquidation-travels', travelsResponse);
    return travelsResponse;
  }

  async getDestinations(travelId: string): Promise<Destination[]> {
    let params = {
      'travel-id': travelId,
    };

    let destinationResponse: Destination[];
    this.progressbarService.show();
    destinationResponse = await this.http
      .get<Destination[]>(this.apiService.destinations, { params: params })
      .toPromise();
    this.progressbarService.hide();
    return destinationResponse;
  }

  getCenters(): Observable<CenterIdentifier[]> {

    return this.apiService
    .get(this.apiService.centers)
    .pipe(
      map(response =>
        response.map(
          (centerIdentifier: Center) =>
            new CenterIdentifier(centerIdentifier),
        ),
      ),
    );
  }

  getAdminCenters(): Observable<CenterIdentifier[]> {

    return this.apiService
    .get(this.apiService.adminCenters)
    .pipe(
      map(response =>
        response.map(
          (centerIdentifier: CenterAdmin) =>
            new CenterAdminIdentifier(centerIdentifier),
        )
      ),
    );
  }

  getTransferCenters(): Observable<CenterIdentifier[]> {

    return this.apiService
    .get(this.apiService.adminCenters)
    .pipe(
      map(response => {
        const allCenters = {
          centerNumber: 'all',
          centerName: 'Todos',
          centerCoordinates: '',
          isInternalTravel: false,
        }
        response.splice(0, 0, allCenters)
        return response.map(
          (centerIdentifier: CenterAdmin) =>
            new CenterAdminIdentifier(centerIdentifier),
        );
      }
      ),
    );
  }

  getRegions(): Observable<RegionIdentifier[]> {

    return this.apiService
    .get(this.apiService.regions)
    .pipe(
      map(response =>
        response.map(
          (adminRegion: AdminRegion) =>
              new RegionIdentifier(adminRegion)
        ),
      ),
    );
  }

  getTravels(centerNumber: string, status: string): Observable<TransferTravel[]> {
    let params = {
      'status': status,
      'date': moment().format('YYYY-MM-DD'),
    };
    return this.apiService
    .get(`${this.apiService.centers}/${centerNumber}/internal-travels`, params)
    .pipe(
      map((response) =>
        response.map(
          (travelResponse: TransferTravelResponse) => {
            const travel = new TransferTravel(travelResponse)
            this.getTransferPositions(travel.travelId.toString()).subscribe(res => {
              if (res) {
                return travel.positions = res
              } else {
                return null
              }

            })
            return travel;
          }
        ),
      ),
    );
  }

  getTransferPositions(travelId: string): Observable<TransferPosition[]> {
    return this.apiService
      .get(`${this.apiService.internalTravels}/${travelId}/positions`)
        .pipe(map((data: TransferPositionResponse[]) => {
          return data.map(element => new TransferPosition(element))
        }))
  }

  async getPositions(destinationId: string): Promise<Position[]> {
    let params = {
      'destination-id': destinationId,
    };

    let positionsResponse: Position[];
    this.progressbarService.show();
    positionsResponse = await this.http
      .get<Position[]>(this.apiService.positions, { params: params })
      .toPromise();
    this.progressbarService.hide();
    return positionsResponse;
  }

  async getPositionBatchs(positionId: string): Promise<Batch[]> {

    let batchs: Batch[];
    this.progressbarService.show();
    batchs = await this.http
      .get<Batch[]>(`${this.apiService.positions}/${positionId}/batchs` ).pipe(
        map(
          (result:Batch[])=>{
             if (result.length>0){
             return result.map(
                (item)=>{
                  const batch:Batch={
                    batchId:item.batchId,
                    positionId:item.positionId,
                    batch:item.batch,
                    subPositionNumber:item.subPositionNumber,
                    quantity:item.quantity,
                    rejectedQuantity:item.rejectedQuantity?item.rejectedQuantity:null,
                  }
                  return batch;
                }
              )
             }
             return [];
          }
        )
      )
      .toPromise();
    this.progressbarService.hide();
    return batchs;
  }

  async setPositionsBatch(positionId:string,data:{ batchId: string, rejectedQuantity:number}[]): Promise<boolean>{
    let batchs: Batch[];
    this.progressbarService.show();
    batchs = await this.http
      .post<Batch[]>(`${this.apiService.positions}/${positionId}/batchs`,data ).toPromise();

    this.progressbarService.hide();
    return batchs?true:false;
  }

  async getObservations(destinationId: number): Promise<Observation[]> {
    let observationsResponse: Observation[];
    this.progressbarService.show();
    observationsResponse = await this.http
      .get<Observation[]>(
        this.apiService.liquidations +
          '/destination/' +
          destinationId +
          '/observations',
      )
      .toPromise();
    this.progressbarService.hide();
    return observationsResponse;
  }

  async getDestinationInformation(
    travelId: string,
  ): Promise<DestinationHeaders> {
    let params = {
      'travel-id': travelId,
    };

    let destinationResponse: DestinationHeaders;
    this.progressbarService.show();
    destinationResponse = await this.http
      .get<DestinationHeaders>(this.apiService.destinationInformation, {
        params: params,
      })
      .toPromise();
    this.progressbarService.hide();
    return destinationResponse;
  }

  async getLiquidatorInformation(destination: string): Promise<Liquidator> {
    const nombre = destination.replace(/@.*$/, '');
    const params = {
      username: nombre,
    };
    let response: Liquidator;
    this.progressbarService.show();
    response = await this.http
      .get<Liquidator>(this.apiService.liquidatorInformation, {
        params: params,
      })
      .toPromise();
    this.progressbarService.hide();
    return response;
  }

  async checkDelivery(destination: string): Promise<CheckDelivery> {
    const params = {
      'destination-id': destination,
    };
    let response: CheckDelivery;
    this.progressbarService.show();
    response = await this.http
      .put<CheckDelivery>(
        this.apiService.checkDelivery,
        {},
        {
          params: params,
        },
      )
      .toPromise();
    this.progressbarService.hide();
    return response;
  }

  get travel() {
    return this.storage.get(this.config.stateTravel).travel;
  }

  setState(data: any): void {
    this.storage.set(this.config.stateTravel, data);
  }

  get superState() {
    return this.storage.get(this.config.superState).superState;
  }

  setSuperState(data: any): void {
    this.storage.set(this.config.superState, data);
  }

  async getDestinationsDocumentPhotos(
    travelId: string,
  ): Promise<TravelPhotos[]> {
    let params = {
      'destination-id': travelId,
    };

    let photos: TravelPhotos[];
    this.progressbarService.show();
    photos = await this.http
      .get<TravelPhotos[]>(
        `${this.apiService.destinationsPhotos}/documents-photos`,
        {
          params: params,
        },
      )
      .toPromise();
    this.progressbarService.hide();
    return photos;
  }

  async getDestinationsProductPhotos(
    travelId: string,
  ): Promise<TravelPhotos[]> {
    let params = {
      'destination-id': travelId,
    };

    let photos: TravelPhotos[];
    this.progressbarService.show();
    photos = await this.http
      .get<TravelPhotos[]>(
        `${this.apiService.destinationsPhotos}/evidence-photos`,
        {
          params: params,
        },
      )
      .toPromise();
    this.progressbarService.hide();
    return photos;
  }

  async getReportTransfers(
    centerNumber: string,
    date?: string
  ): Promise<Blob> {
    let params: Params;
    let excel: Blob;

    params = {
      'center-number': centerNumber
    };

    if (date) {
      let dateSearch = date.split(' ');
      params = {
        ...params,
        'start-date': dateSearch[0],
        'end-date': dateSearch[1],
      }
    }

    excel = await this.http
      .get(
        this.apiService.internalTravelsExcel,
        {
          params: params,
          responseType: 'blob'
        },
      )
      .toPromise();

    return excel;
  }

  async getDestinationsAllPhotos(travelId: string): Promise<TravelPhotos[]> {
    let params = {
      'destination-id': travelId,
    };

    let productPhotos: TravelPhotos[];
    let documentPhotos: TravelPhotos[];

    this.progressbarService.show();
    productPhotos = await this.http
      .get<TravelPhotos[]>(
        `${this.apiService.destinationsPhotos}/evidence-photos`,
        {
          params: params,
        },
      )
      .toPromise();

    documentPhotos = await this.http
      .get<TravelPhotos[]>(
        `${this.apiService.destinationsPhotos}/documents-photos`,
        {
          params: params,
        },
      )
      .toPromise();

    this.progressbarService.hide();
    return productPhotos.concat(documentPhotos);
  }

  async getGenerateTravelQR(travelId: string, userid: string): Promise<string> {
    let params = {
      'travel-id': travelId,
      'liquidator-id': userid,
    };
    const qrImage = await this.http
      .get<string>(`${this.apiService.liquidationTravelQR}`, {
        params: params,
      })
      .toPromise();
    return qrImage['dataQR'];
  }

  async getValidatedQR(travelId: string): Promise<LiquidatorValidateQR> {
    let params = {
      'travel-id': travelId,
    };
    const qrValidated = await this.http
      .get<LiquidatorValidateQR>(`${this.apiService.liquidationValidateQR}`, {
        params: params,
      })
      .toPromise();
    return qrValidated;
  }

  async getObservationsSelectTypes(): Promise<Observation[]> {
    let observations: Observation[];

    this.progressbarService.show();
    observations = await this.http
      .get<Observation[]>(`${this.apiService.observationTypes}`)
      .pipe(
        map(data => {
          return data.map(element => {
            const obs = <Observation>{
              observationId: element.observationId,
              observationCode: element.observationCode,
              observationDescription: element.observationDescription,
              checked: false
            }
            switch (obs.observationId) {
              case 5:
                obs.positionOrder = 1;
                break;
              case 1:
                obs.positionOrder = 2;
                break;
              case 2:
                obs.positionOrder = 3;
                break;
              case 3:
                obs.positionOrder = 4;
                break;
              case 4:
                obs.positionOrder = 5;
                break;
              default:
                obs.positionOrder = 100;
                break;
            }

            return obs;
          });
        }),
      )
      .toPromise();
    this.progressbarService.hide();
    return observations;
  }

  async setObservationsOfDestination(
    destinations: Destination[],
  ): Promise<boolean> {
    let response: boolean = false;

    let processData = [];
    processData = destinations.map(destination => {
      const observationsArrayId = [];
      destination.observations.forEach(observation => {
        if (observation.checked) {
          observationsArrayId.push(observation.observationId);
        }
      });
      return {
        deliveriesId: destination.destinationId,
        observationsId: observationsArrayId,
      };
    });

    this.progressbarService.show();

    await this.http
      .post<any>(
        `${this.apiService.liquidationdeliveryObservations}`,
        processData,
        { observe: 'response' },
      )
      .toPromise()
      .then(res => {
        response = res.ok;
      })
      .catch(error => {
        this.progressbarService.hide();
      });
    this.progressbarService.hide();
    if (response) {
      return true;
    } else {
      return false;
    }
  }

  async setTravelToObserved(travelId: string): Promise<boolean> {
    let response: boolean = false;
    this.progressbarService.show();
    await this.http
      .put<any>(
        `${this.apiService.liquidationUpdateTravelToObserver}/${travelId}/observed`,
        {},
        { observe: 'response' },
      )
      .toPromise()
      .then(res => {
        response = res.ok;
      })
      .catch(error => {
        this.progressbarService.hide();
        console.error(error);
      });
    this.progressbarService.hide();
    if (response) {
      return true;
    } else {
      return false;
    }
  }

  async setTravelToLiquidated(
    travelId: string,
    userPrincipalName: string,
  ): Promise<boolean> {
    let response: boolean = false;
    this.progressbarService.show();

    await this.http
      .patch<any>(
        `${this.apiService.liquidationUpdateTravelToObserver}/${travelId}/liquidate`,
        {},
        {
          headers: {
            'x-session-user': userPrincipalName,
          },
          observe: 'response',
        },
      )
      .toPromise()
      .then(res => {
        response = res.ok;
      })
      .catch(error => {
        this.progressbarService.hide();
        console.error(error);
      });
    this.progressbarService.hide();
    if (response) {
      return true;
    } else {
      return false;
    }
  }

  async getImageFromUrl(url: string): Promise<Blob> {
    const result: Promise<any> = fetch(url, {
      method: 'GET',
    }).then<Blob>(function(response) {
      return response.blob();
    });
    return result;
  }

  async checkTransport(travelId: number) {
    let response: boolean = false;
    await this.http
      .put(
        this.apiService.liquidations + '/travels/' + travelId + '/revise',
        {},
        { observe: 'response' },
      )
      .toPromise()
      .then(res => {
        response = res.ok;
      });

    return response;
  }

  async checkDeliveryInObservation(destination: string): Promise<CheckDeliveryInObservation> {
    let response: CheckDeliveryInObservation;
    this.progressbarService.show();
    response = await this.http
      .put<CheckDeliveryInObservation>(
        `${this.apiService.checkDeliveryInObservation}/${destination}/observations/check`,
        {}
      )
      .toPromise();
    this.progressbarService.hide();
    return response;
  }


  async getAllTravels(centerNumber: string, centerOriginName:string):Promise<any>{
    const params = {
      'date': moment().format('YYYY-MM-DD')
    };

    const dateConverter=function(date:string):Date{
      return moment(date, "DD/MM/YY - HH:mm A").toDate();
    }

      return combineLatest([
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"truckArrival"} ),
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"unloadingStart"} ),
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"unloadingEnd"} ),
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"arrival"} ),
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"loadingStart"} ),
        this.apiService.get(`${this.apiService.centers}/${centerNumber}/internal-travels`, {...params, status:"loadingEnd"} )
      ]).pipe(
        map(
          ([truckArrival,unloadingStart,unloadingEnd,arrival,loadingStart,loadingEnd])=>{
            return {
              unloadTravels: {
                total: truckArrival.length+unloadingStart.length+unloadingEnd.length,
                truckArrival: truckArrival?truckArrival.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName; travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[],
                unloadingEnd:unloadingEnd?unloadingEnd.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName; travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[],
                unloadingStart: unloadingStart?unloadingStart.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName; travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[]
              },
              loadTravels: {
                total: arrival.length+loadingStart.length+loadingEnd.length,
                arrival:  arrival?arrival.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName;  travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[],
                loadingEnd:  loadingEnd?loadingEnd.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName;  travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[],
                loadingStart:  loadingStart?loadingStart.map( (item)=>{let travel=new TransferTravel(item);travel.suppliesOriginName=centerOriginName;  travel.dateTimeFilter=dateConverter(travel.dateTime); return travel;}):[],
              },
            }
          }
        )
      ).toPromise();

  }

  async getTravelObservations(travelId:string):Promise<Partial<Observation[]>>{
    return this.apiService
    .get(`${this.apiService.internalTravels}/internal-travels/${travelId}/observations`)
    .toPromise();
  }

  async getTravelObservationTypes():Promise<Observation[]>{
    return this.apiService
    .get(`${this.apiService.internalTravels}/observations`)
    .toPromise();
  }

  async saveTravelObservation(data:number[],travelId:string ):Promise<Observation[]>{
    return this.apiService
    .post(`${this.apiService.internalTravels}/internal-travels/${travelId}/observations`,data)
    .toPromise();
  }


  async getTravelSAPStatus(travelId: string): Promise<any> {
    let response: any;
    this.progressbarService.show();
    response = await this.http
      .get<any>(
        `${this.apiService.liquidationUpdateTravelToObserver}/${travelId}/batchs/progress`)
      .toPromise();
    this.progressbarService.hide();
    return response;
  }

  async saveTravelBatchsToSAP(travelId: string, liquidatorId:string): Promise<boolean> {
    const bodyRequest={
      liquidatorId:liquidatorId
    }

    this.progressbarService.show();
    const response = await this.http
      .post<boolean>(
        `${this.apiService.liquidationUpdateTravelToObserver}/${travelId}/batchs/sap`,bodyRequest).pipe(
            map(
              (response)=>{
                return true;
              }
            ),
            catchError(
              (error:HttpErrorResponse)=>{
                return of(false);
              }
            )
          )
      .toPromise();
    this.progressbarService.hide();
    return response;
  }

  //NUEVO DESARROLLO - BEGIN
  async readQR(travelId: string, liquidatorId:string, expireDate: string): Promise<void>{
    await this.http
      .post<void>(`${this.apiService.liquidationUpdateTravelToObserver}/${travelId}/liquidate/readQr`,
      {"dataQR":"{\"travelID\":" + travelId +
                ",\"liquidatorId\":"+ liquidatorId +
                ",\"expire\":\"" + expireDate +
                "\"}"}
      ).toPromise();
  }

  async getReportLiquidations(
    centerNumber: string,
    specificType: string,
    request? : {
      datesearch: { dateStart: string; dateEnd: string } | null;
      textsearch: string[] | null;
    }
  ): Promise<HttpResponse<Blob>> {
    let res: HttpResponse<Blob>;
    let params: Params = {
      'center-number': centerNumber,
      type: specificType,
    };

    if (request.datesearch) {
      params['start-date'] = request.datesearch.dateStart;
      params['end-date'] = request.datesearch.dateEnd
        ? request.datesearch.dateEnd
        : request.datesearch.dateStart;
    } else {
      params['number-transport'] = request.textsearch;
    }

    res = await this.http
      .get(
        this.apiService.reportLiquidations,
        {
          params: params,
          responseType: 'blob',
          observe: "response"
        },
      )
      .toPromise();
    return res;
  }

  async getReportLiquidationsOfAllTypes(
    centerNumber: string,
    specificType: string,
    request? : {
      datesearch: { dateStart: string; dateEnd: string } | null;
      textsearch: string[] | null;
    }
  ): Promise<HttpResponse<Blob>> {
    let res: HttpResponse<Blob>;
    let params: Params = {
      'center-number': centerNumber,
      type: specificType,
    };

    res = await this.http
      .get(
        this.apiService.reportLiquidations,
        {
          params: params,
          responseType: 'blob',
          observe: "response"
        },
      )
      .toPromise();
    return res;
  }
  //NUEVO DESARROLLO - END
}
