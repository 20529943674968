export interface UserResponse {
  id: string;
  displayName: string;
  userPrincipalName: string;
  givenName: string;
  mail: string;
  mobilePhone: string;
  companyName: string;
  city: string;
  country: string;
  state: string;
  jobTitle: string;
  accountEnabled: string;
  officeLocation: string;
  region: string;
}

export class User {
  id: string;
  name: string;
  userPrincipalName: string;
  givenName: string;
  mail: string;
  mobilePhone: string;
  companyName: string;
  city: string;
  country: string;
  state: string;
  jobTitle: string;
  accountEnabled: string;
  officeLocation: string;
  region: string;
  role: string;
  allowedModules: string[];

  constructor(user: UserResponse) {
    this.id = user.id || '';
    this.name = user.displayName || '';
    this.userPrincipalName = user.userPrincipalName || '';
    this.givenName = user.givenName || '';
    this.mail = user.mail || '';
    this.mobilePhone = user.mobilePhone || '';
    this.companyName = user.companyName || '';
    this.city = user.city || '';
    this.country = user.country || '';
    this.state = user.state || '';
    this.jobTitle = user.jobTitle || '';
    this.accountEnabled = user.accountEnabled || '';
    this.officeLocation = user.officeLocation || '';
    this.region = user.region || '';
    this.role = '';
    this.allowedModules = []
  }

  update(currentUser: User, newUser: UserResponse): User {
    currentUser.id = newUser.id ?? currentUser.id;
    currentUser.name = newUser.displayName ?? currentUser.name;
    currentUser.userPrincipalName = newUser.userPrincipalName ?? currentUser.userPrincipalName;
    currentUser.givenName = newUser.givenName ?? currentUser.givenName;
    currentUser.mail = newUser.mail ?? currentUser.mail;
    currentUser.mobilePhone = newUser.mobilePhone ?? currentUser.mobilePhone;
    currentUser.companyName = newUser.companyName ?? currentUser.companyName;
    currentUser.city = newUser.city ?? currentUser.city;
    currentUser.country = newUser.country ?? currentUser.country;
    currentUser.state = newUser.state ?? currentUser.state;
    currentUser.jobTitle = newUser.jobTitle ?? currentUser.jobTitle;
    currentUser.accountEnabled = newUser.accountEnabled ?? currentUser.accountEnabled;
    currentUser.officeLocation = newUser.officeLocation ?? currentUser.officeLocation;
    currentUser.region = newUser.region ?? currentUser.region;

    return currentUser;
  }
}
