import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {  NgModule } from '@angular/core';

import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
    BrowserCacheLocation,
    LogLevel,
  } from '@azure/msal-browser';
  
  import {
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalModule as AzureMsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration,
    MsalRedirectComponent,
  } from '@azure/msal-angular';
  import { UTILS } from '@alitrack/commons';
  import { environment } from '@shell/environments/environment';
  
  export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.azureAD.client_id,
        authority: `https://login.microsoftonline.com/${environment.azureAD.tenant_id}/`,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: UTILS.isIE(),
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(environment.api.v1.liquidations, ['user.read']);
    protectedResourceMap.set(environment.api.v1.auth + '/user-type', ['user.read']);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    };
  }

@NgModule({
  imports: [AzureMsalModule],
  bootstrap: [MsalRedirectComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class MsalModule {}
