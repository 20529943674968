export const environment = {
  production: true,
  mock: false,
  version: 'v1',
  api: {
    v1: {
      auth: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-auth-v1/v1',
      travels: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-travels-v1/v1',
      internalTravels: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-internal-travels-v1/v1',
      deliveries: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-deliveries-v1/v1',
      liquidations: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-liquidations-v1/v1',
      blobstorage: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-blobstorage-v1/v1',
      admin: 'https://gw.alitrack.alicorp.com.pe/alitrack-exp-admin-v1/v1',
    }
  },
  cdn: 'https://www.alitrack.alicorp.com.pe/assets'
};
