import { Injectable } from '@angular/core';

import { AlitrackApiService } from '@alitrack/commons';
import { environment } from '../environments/environment';

@Injectable()
export class CRMApiService extends AlitrackApiService {
  // auth

  generateToken = environment.api.v1.auth + '/login';
  refreshToken = environment.api.v1.auth + '/token-refresh';
  user = environment.api.v1.auth + '/user';
  userType = environment.api.v1.auth + '/user-type';

  // admin
  adminInformation = environment.api.v1.admin + '/admins'
  regions = environment.api.v1.admin + '/regions';
  adminCenters = environment.api.v1.admin + '/centers'
  // searchValidators = environment.api.v1.admin + '/validators';
  // searchCarriers = environment.api.v1.admin + '/truck-drivers';

  // deliveries
  delivery = environment.api.v1.deliveries;

  // travels
  travels = environment.api.v1.travels;

  // internal-travels
  administrativeOperatorInformation = environment.api.v1.internalTravels + '/internal-travel-user';
  centers = environment.api.v1.internalTravels + '/centers';
  internalTravels = environment.api.v1.internalTravels;
  internalTravelsExcel = environment.api.v1.internalTravels +'/internal-travels/excel';

  // liquidations
  liquidations = environment.api.v1.liquidations;
  liquidatorInformation = environment.api.v1.liquidations + '/liquidator-information';
  destinations = environment.api.v1.liquidations + '/deliveries';
  positions = environment.api.v1.liquidations + '/positions';
  destinationInformation = environment.api.v1.liquidations + '/destination-information';
  checkDelivery = environment.api.v1.liquidations + '/check-delivery';
  liquidationTravelQR = environment.api.v1.liquidations + '/generate-qr';
  liquidationValidateQR = environment.api.v1.liquidations + '/validate-qr-reading';
  destinationsPhotos = environment.api.v1.blobstorage;
  observationTypes =  environment.api.v1.liquidations + '/observations';
  liquidationdeliveryObservations =  environment.api.v1.liquidations + '/deliveries/observations';
  liquidationUpdateTravelToObserver =  environment.api.v1.liquidations + '/travels';
  checkDeliveryInObservation = environment.api.v1.liquidations + '/destination';
  reportLiquidations = environment.api.v1.liquidations + '/travels/liquidation/excel'
}
