export const Messages = {
  successSave: 'Registro satisfactorio.',
  successUpdate: 'Actualización satisfactoria.',
  successDelete: 'Se eliminó satisfactoriamente.',
  successPrint: 'Impresión satisfactoria',
  formRequired: 'Falta completar y/o corregir los datos obligatorios.',
  successDeleteLinePortability: 'La línea fue eliminada correctamente.',
  cancelPaymentSuccess: 'Se realizó la anulación del pedido XXX exitosamente.',
  cancelPaymentError: 'No se pudo realizar la anulación del pedido XXX.',
  mustEnterEmail: 'Debe proporcionar un correo electrónico válido.',
  consoleSecurity: 'Se ha detectado un intento de acceso prohibido.\nEstá siendo reportado al área de seguridad.\n\n',
};
