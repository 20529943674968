import { Center } from "./center.interface";
import { Destination, Observation, TransferPosition } from "./destination.interface";

export interface Travel {
    travelId: number;
    transportNumber: string;
    driverDni: string;
    driverLicenseNumber: string;
    driverName: string;
    plateNumber: string;
    company_ruc: string;
    company_name: string;
    travelStatus: number;
    centers: Center;
    totalPerTravel: TotalTravel;
    destinationStatus: number | null;
    destinations?: Destination[];
    isAllDestinationObservationChecked?:boolean;
    observations?:Observation[];
    isFOCoordinator: boolean;
}

export interface TransferTravelResponse {
    travelId: number;
    transportNumber: string;
    plateNumber: string;
    companyRuc: string;
    companyName: string;
    suppliesDestinationName: string;
    dateTime: string;
    weight:number;
}

export class TransferTravel {
    travelId: number;
    transportNumber: string;
    plateNumber: string;
    companyRuc: string;
    companyName: string;
    suppliesDestinationName: string;
    suppliesOriginName:string;
    dateTime: string;
    positions: TransferPosition[];
    weight:number;
    observations:Observation[];
    savedObservations:Observation[];
    savedObservationsString:string;
    dateTimeFilter:Date;

    constructor(travelResponse: TransferTravelResponse) {
        this.travelId = travelResponse.travelId ? travelResponse.travelId : null;
        this.transportNumber = travelResponse.transportNumber ? travelResponse.transportNumber : '';
        this.plateNumber = travelResponse.plateNumber ? travelResponse.plateNumber : '';
        this.companyRuc = travelResponse.companyRuc ? travelResponse.companyRuc : '';
        this.companyName = travelResponse.companyName ? travelResponse.companyName : '';
        this.suppliesDestinationName = travelResponse.suppliesDestinationName ? travelResponse.suppliesDestinationName : '';
        this.dateTime = travelResponse.dateTime ? travelResponse.dateTime : '';
        this.weight = travelResponse.weight ? travelResponse.weight : null;
        // this.positions = [];
    }
}

export class TotalTravel {
    total: number;
    currency: string;
}
