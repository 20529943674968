import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';

import { EventBus as AlitrackEventBus, IEventBus } from '@alitrack/core';

@Injectable()
export class EventBus extends AlitrackEventBus {}

export function eventBus(): IEventBus {
  return EventBus.getInstance();
}

@NgModule()
export class EventBusModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: EventBusModule,
      providers: [{ provide: EventBus, useFactory: eventBus }]
    };
  }
}
