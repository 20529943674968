import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shell/shell.module').then(m => m.ShellModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled',
    useHash: false,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
