import { CommonsModule } from '@alitrack/commons';
import { EventBusModule } from '@alitrack/commons/event-bus';
import { StorageModule, IStorageConfig } from '@alitrack/commons/storage';
import { AlitrackCommonsModule } from '@alitrack/crm/commons';
import { MsalModule } from './msal.module';

const secretKey = '65537';
const storageConfig: IStorageConfig = { secretKey };

export const Modules: any[] = [
  CommonsModule,
  EventBusModule.forRoot(),
  StorageModule.forRoot(storageConfig),
  AlitrackCommonsModule,
  MsalModule
];
